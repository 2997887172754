import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ScrollService {

  constructor() { }

  private _scrollToSectionSource = new Subject<string>();

  scrollToSection$ = this._scrollToSectionSource.asObservable();

  changeSection(section) {
    this._scrollToSectionSource.next(section);
  }
}
