import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PortalModule } from './portal/portal.module';
import { PrimeNgModule } from './primeNg/primeng.module';
import { PortalV1Component } from './portal-v1/portal-v1.component';
import { ContactComponent } from './portal-v1/contact/contact.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollService } from './portal/services/scroll.service';
import { HttpClientModule } from '@angular/common/http';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {MenuItem} from 'primeng/api';
import { API_BASE_URL, OffersClient, OpinionClient, PortalClient } from './services/service-proxy'

@NgModule({
  declarations: [
    AppComponent,
    PortalV1Component,
    ContactComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    PortalModule,
    BrowserAnimationsModule,
    PrimeNgModule,
    FormsModule,
    ReactiveFormsModule,
    TieredMenuModule
  ],
  providers: [
    ScrollService,
    PortalClient,
    OffersClient,
    OpinionClient,
    {provide: API_BASE_URL, useValue: 'https://api.nightingale.pl' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
