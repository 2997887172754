import { Component, OnInit } from '@angular/core';
import { OpinionClient, OpinionDto, PagedQueryInput } from 'src/app/services/service-proxy';

@Component({
  selector: 'app-opinions-section',
  templateUrl: './opinions-section.component.html',
  styleUrls: ['./opinions-section.component.scss']
})
export class OpinionsSectionComponent implements OnInit {

  responsiveOptions;
  opinions: OpinionDto[];

  constructor(
    private _opinionClient: OpinionClient) {
    this.responsiveOptions = [
      {
        breakpoint: '1440px',
        numVisible: 3,
        numScroll: 1
      },
      {
        breakpoint: '1024px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {
    this._opinionClient.getOpinions(new PagedQueryInput({
      globalSearch: "",
      skip: 0,
      pageSize: 100
    })).subscribe(x => {
      this.opinions = x.opinions;
    });
  }
}
