import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { CardModule } from 'primeng/card';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ChartModule } from 'primeng/chart';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { InputMaskModule } from 'primeng/inputmask';
import { DividerModule } from 'primeng/divider';
import { CarouselModule } from 'primeng/carousel';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    TableModule,
    ButtonModule,
    TabMenuModule,
    TabViewModule,
    InputTextModule,
    CalendarModule,
    InputNumberModule,
    InputTextareaModule,
    DropdownModule,
    CardModule,
    DynamicDialogModule,
    CheckboxModule,
    ToastModule,
    ChartModule,
    SidebarModule,
    MenuModule,
    FullCalendarModule,
    ConfirmDialogModule,
    DividerModule,
    InputMaskModule,
    CarouselModule
  ],
  providers: [
    DialogService,
    MessageService,
    ConfirmationService
  ]
})
export class PrimeNgModule { }
