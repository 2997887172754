<ng-container [ngSwitch]="getButtonType()">
  <ng-container *ngSwitchCase="'text'" [ngTemplateOutlet]="text"></ng-container>
  <ng-container *ngSwitchCase="'mixed'" [ngTemplateOutlet]="mixed"></ng-container>
  <ng-container *ngSwitchCase="'image'" [ngTemplateOutlet]="image"></ng-container>
</ng-container>

<ng-template #text>
  <button 
    [style.padding-left.px]="padding"
    [style.padding-right.px]="padding"
    type="button"
    (click)="onButtonClick()">
    {{buttonText}}
</button>
</ng-template>

<ng-template #mixed>
  <button
    [style.padding-left.px]="padding"
    [style.padding-right.px]="padding"
    type="button"
    (click)="onButtonClick()">
    {{buttonText}}
    <img style="margin-left: 20px;" [src]="src">
  </button>
</ng-template>

<ng-template #image>
  <img alt="image button"
       [src]="src"
       (click)="onButtonClick()">
</ng-template>
