import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})

export class ToastService {

  constructor(private messageService: MessageService) { }

  public showSuccessToast(detail: string) {
    this.messageService.add({ severity: 'success', summary: 'Operacja zakończona', detail: detail });
  }

  public showErrorToast(detail: string) {
    this.messageService.add({ severity: 'error', summary: 'Błąd', detail: detail });
  }

  public showWarnToast(detail: string) {
    this.messageService.add({ severity: 'warn', summary: 'Akcja niedostępna', detail: detail });
  }
}
