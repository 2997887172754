import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import { Opinion } from './model/opinion';

@Component({
  selector: 'app-portal-v1',
  templateUrl: './portal-v1.component.html',
  styleUrls: ['./portal-v1.component.scss']
})
export class PortalV1Component implements OnInit {

  items: MenuItem[];
  opinions: Opinion[];
  responsiveOptions;

  constructor() { }

  ngOnInit(): void {

    this.items = [
      {
        label: 'O MNIE',
        command: (event) => this.scrollToSection('who')
      },
      {
        label: 'KURSY',
        command: (event) => this.scrollToSection('courses')
      },
      {
        label: 'OPINIE',
        command: (event) => this.scrollToSection('opinions')
      },
      {
        label: 'KONTAKT',
        command: (event) => this.scrollToSection('contact')
      }
    ];

    this.opinions = [
      { name: "Marta", text: "\"Nie znajdziecie tu nudnego klepania słówek na metodę \"ZZZ\", gramatyki tłumaczonej tak, że nie nadążasz z myśleniem czy tłumaczenia po łebkach. Zajęcia są prowadzone w pełni z Waszym udziałem. Godzina leci stanowczo za szybko, tak samo jak czas, po którym zdajecie sobie sprawę, że to działa :)\"" },
      { name: "Kasia", text: "\"Chciałabym podzielić się moją bardzo pozytywną opinią. Nauczyciel z pasją, który, co najważniejsze potrafi przekazać swoją wiedzę. Pomaga ułożyc wszystko w głowie. Cieszę się, że mogę korzystać z lekcji online. Nareszcie angielski nie przeraża i chce mi sie uczyć. Poznaję i uczę się poprawnej wymowy i gramatyki w sposób bardzo przyjemny. Polecam w 1000%! Dzięki za super lekcje!\"" },
      { name: "Małgosia", text: "\"Z Grzegorzem uczę się online od około 1.5 roku. Jestem bardzo zadowolona, Grzegorz był wsparciem w trudnych chwilach kiedy miałam trudności w nauce i chciałam rezygnować. Zawsze rzetelny, przygotowany, cierpliwy i przyjacielski. Polecam lekcje z całego serca!\"" },
      { name: "Sylwia", text: "\"Uczę sie języka angielskiego z Grześkiem już ponad rok. Jest on bardzo cierpliwym nauczycielem a wszystkie lekcje są prowadzone bardzo przejrzyście i zrozumiale. Z całą odpowiedzialnością mogę polecić mojego nauczyciela języka angielskiego Grzegorza Szczurka.\"" },
      { name: "Ola", text: "\"Bardzo polecam lekcje z panem Grzesiem! Uczę się z nim już drugi rok i naprawdę widzę postępy! Świetnie tłumaczy a lekcje mijają w super atmosferze. Polecam w 1000000%!\"" },

    ]

    this.responsiveOptions = [
      {
        breakpoint: '1440px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '1024px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '425px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  public scrollToSection(id: string) {
    const element = document.getElementById(id);

    element.scrollIntoView({ behavior: 'smooth' });
  }
}
