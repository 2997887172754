<div class="section-background">
  <div class="section-container">
    <div class="about-row ctr-vh wrap">

      <div class="about-text ctr-h col">
        <span class="header-1">O mnie</span>
        <span class="description-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span>
        
        <div class="ctr-vh">
          <app-button class="rounded dark" [padding]="40" [buttonText]="'Zapisz się'"></app-button>
          <a class="link-1" href="https://www.w3schools.com">Kliknij i dowiedz się więcej</a>
        </div>
      </div>

      <div class="about-embedded">
        <img src="../../../../assets/images/portal/about/about-mock.svg" width="100%">        
      </div>

    </div>
  </div>
</div>
<div style="background-color: white;">
  <img src="../../../../assets/images/portal/about/about-bottom-filler.svg" width="100%">    
</div>