<div class="opinion">
  
  <div class="header">
    <span>{{opinion.name}}</span>
  </div>

  <div class="text">
    <span>{{opinion.text}}</span>
  </div>

</div>