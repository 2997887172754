<div class="section-background col">
  <div class="section-container">
    <div class="section-header col">
      <span>KURSY</span>

      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis volutpat, scelerisque quis dis dolor. Odio sed suspendisse pellentesque convallis magnis sit vitae platea. Ante non amet tellus volutpat quis at venenatis a amet. Non tortor id a sem. Duis tempus ut tellus bibendum. Vel, convallis tellus fusce vel. Libero vel etiam sit ultricies eu, tortor, ipsum ipsum.</span>
    </div>
  </div>
  <div class="section-offers">

    <div *ngFor="let offer of offers">
      <app-offer [offer]="offer"></app-offer>
    </div>

  </div>
</div>