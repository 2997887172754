<div class="section-background">
  <div class="section-container ctr-v sb">

    <div>
      <img src="../../../../assets/images/logo.svg" width="200px">
    </div>

    <div class="footer-social">
      <app-button [src]="'../../../../assets/images/portal/footer/youtube.svg'" (onClick)="redirectTo('https://youtube.com')"></app-button>
      <app-button [src]="'../../../../assets/images/portal/footer/facebook.svg'" (onClick)="redirectTo('https://facebook.com')"></app-button>
      <app-button [src]="'../../../../assets/images/portal/footer/instagram.svg'" (onClick)="redirectTo('https://instagram.com')"></app-button>
    </div>

  </div>
</div>