import { Component, OnInit } from '@angular/core';
import { ScrollService } from './services/scroll.service';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})

export class PortalComponent implements OnInit {

  constructor(private _scrollService: ScrollService) { }

  ngOnInit(): void {
    this.InitObservables();
  }

  public scrollToSection(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  private InitObservables() {
    this._scrollService.scrollToSection$.subscribe(x => {
      const element = document.getElementById(x);

      if (element) {
        this.scrollToSection(element);
      }
    })
  }
}
