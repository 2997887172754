<div class="navbar-container col wrap">
  <div class="navbar-logo-container ctr-h">
    <img src="../../../../assets/images/logo.svg">
  </div>

  <div class="button-list ctr-h sa wrap">
    <app-button class="rounded dark" [buttonText]="'O MNIE'" (onClick)="scrollToSection('about')" ></app-button>
    <app-button class="rounded dark" [buttonText]="'KURSY'" (onClick)="scrollToSection('offer')" ></app-button>
    <app-button class="rounded dark" [buttonText]="'OPINIE'" (onClick)="scrollToSection('opinions')" ></app-button>
    <app-button class="rounded dark" [buttonText]="'KONTAKT'" (onClick)="scrollToSection('contact')" ></app-button>
    <app-button class="rounded brown" [buttonText]="'Logowanie'" (onClick)="redirectToLogin()" ></app-button>
  </div>
</div>