<div class="section-background col">
  <div class="section-header">
    <span>OPINIE MOICH KLIENTÓW</span>
  </div>
  <div class="section-container">
    <div class="carousel" *ngIf='opinions'>

      <p-carousel [value]="opinions" 
                  [numVisible]="3" 
                  [numScroll]="1" 
                  [autoplayInterval]="4000" 
                  [circular]="true" 
                  [showIndicators]="false"
                  [responsiveOptions]="responsiveOptions">
        <ng-template let-opinion pTemplate="item">
          <app-opinion [opinion]="opinion"></app-opinion>
        </ng-template>
      </p-carousel>

    </div>
  </div>
</div>