<div class="section">
    <div class="container">
        <div class="menu">
            <img class="logo" src="../../../../assets/images/portal_v1/nightingale-logo.svg">

            <div class="list">
                <button (click)="scrollToSection('who')">O MNIE</button>
                <button (click)="scrollToSection('courses')">KURSY</button>
                <button (click)="scrollToSection('opinions')">OPINIE</button>
                <button (click)="scrollToSection('contact')">KONTAKT</button>
            </div>

            <div class="tier">
                <p-tieredMenu #menu [model]="items" [popup]="true"></p-tieredMenu>
                <button #btn type="button" pButton icon="pi pi-fw pi-list" (click)="menu.toggle($event)"></button>
            </div>
        </div>
    </div>
</div>

<section id="welcome" class="section">
    <div class="container">
        <div class="welcome">
            <div class="text">
                <span class="header">
                    Witaj w NIGHTINGALE!
                </span>
                <span class="description">
                    W szkole języka angielskiego gdzie naprawdę uczysz się angielskiego! Zaparz sobie herbaty, rozsiądź
                    się wygodnie w fotelu i daj się wciągnąć w świat języka angielskiego.
                </span>
            </div>

            <img class="image" src="../../../../assets/images/portal_v1/homies.svg">
        </div>
    </div>
</section>

<section id="who" class="section light-brown">
    <div class="container light-brown">
        <div class="who">
            <img class="image" src="./../../assets/images/portal_v1/me.svg">
            <div class="text">
                <span class="header">
                    Kim jestem?
                </span>
                <span class="description">
                    Zwykłym nauczycielem języka angielskiego, który lubi robić niezwykle rzeczy, a przede wszystkim
                    uczyć angielskiego w sposób zrozumiały i prosty dla każdego, nawet tego najbardziej utwierdzonego
                    ucznia w przekonaniu że się nie da :)
                    <br><br>
                    Z wykształcenia jestem filologiem języka angielskiego, który po nauce i spędzeniu 5 lat w Wielkiej
                    Brytanii postanowił zarażać pasją do tego pięknego języka.
                </span>
            </div>
        </div>
    </div>
</section>

<section id="courses" class="section">
    <div class="container">
        <div class="courses">
            <span class="header">
                Kursy
            </span>
            <span class="description">
                Chcesz zdobyć wymarzoną posadę lub wspinać się po szczeblach kariery? Chciałbyś wyjechać za granicę do
                pracy lub studiować. Niezależnie od Twoich planów i celów język angielski otworzy przed Tobą
                niezliczonych drzwi możliwości.

                Na co jeszcze czekasz? Zapisz się na jeden z poniższych kursów ze mną i pozwól, że pomogę Ci otworzyć
                jedne z tych "drzwi" bez szarpania za klamkę :)
            </span>
        </div>
    </div>
</section>

<div class="section">
    <div class="container">
        <div class="course right">
            <img class="image" src="../../../../assets/images/portal/offer/lesson1.svg">
            <div class="description">
                <span class="header">
                    Zajęcia indywidualne
                </span>
                <span>
                    Zapraszam na lekcję jeden na jeden w sympatycznej atmosferze.
                </span>
                <ul>
                    <li>
                        Na pierwszej lekcji sprawdzę poziom Twojego angielskiego oraz
                        zaproponuję program nauczania dopasowany do Twoich potrzeb i umiejętności.
                    </li>
                    <li>
                        Na zajęciach kładę nacisk na konwersację i poprawną wymowę co pozwala uzyskać
                        zaskakujące rezultaty w krótkim czasie oraz przełamać barierę językową.
                    </li>
                    <li>
                        Korzystamy ze wspólnego notatnika elektronicznego, który jest bardzo wygodny oraz pozwala na
                        prowadzenie wspólnych notatek wraz z moimi wskazówkami poprawnej wymowy i poprawności
                        gramatycznej.
                    </li>
                </ul>
                <span>
                    Sympatyczna atmosfera połączona z indywidualnym planem nauczania to przepis na suckes!
                    Ze mną nie może Ci się nie udać! :)
                </span>
            </div>
        </div>
    </div>
</div>

<section id="opinions" class="section light-blue">
    <div class="container">
        <div class="opinions">
            <span class="header">
                Opinie moich kursantów
            </span>
        </div>
    </div>
</section>

<section id="opinions" class="section">
    <div class="container">
        <div class="carousel">
            <p-carousel [value]="opinions" [numVisible]="3" [numScroll]="1" [autoplayInterval]="4000" [circular]="true"
                [showIndicators]="false" [responsiveOptions]="responsiveOptions">
                <ng-template let-opinion pTemplate="item">
                    <div class="opinion">
                        <span class="name">
                            {{opinion.name}}
                        </span>
                        <span>
                            {{opinion.text}}
                        </span>
                    </div>
                </ng-template>
            </p-carousel>
        </div>
    </div>
</section>

<section id="contact" class="section light-brown">
    <div class="container light-brown">
        <div class="contact">
            <div class="text">
                <span class="header contact">Kontakt</span>
            </div>
            <img class="image" src="../../../../assets/images/portal_v1/hands-up.svg">
        </div>
    </div>
    <div class="container blue radius-top60">
        <div class="form">
            <app-contact></app-contact>
        </div>
    </div>
</section>

<div class="section gray">
    <div class="container">
        <div class="footer">

            <img src="../../../../assets/images/portal_v1/nightingale-logo.svg" class="logo">

            <div class="social">
                <a href="https://www.youtube.com/@nightingale1826" target="_blank">
                    <i class="pi pi-youtube" style="font-size: 2rem; color: white;"></i>
                </a>
                <a href="https://www.facebook.com/profile.php?id=100085304119462" target="_blank">
                    <i class="pi pi-facebook" style="font-size: 2rem; color: white;"></i>
                </a>
                <!-- <a href="">
                    <i class="pi pi-twitter" style="font-size: 2rem; color: white;"></i>
                </a> -->
            </div>
        </div>
    </div>
</div>