<div class="section-background">
  <div class="section-container">
    <div class="top-row">
      <app-navbar></app-navbar>
    </div>

    <!-- <div class="bottom-row">
    <span class="text-white">Hello and Welcome!</span>
    <span class="text-gold">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo donec pretium vulputate nunc metus sem"</span>
  </div> -->
  </div>
</div>