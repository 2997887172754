import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})

export class ButtonComponent implements OnInit {
  @Input() buttonText: string;
  @Input() src: string;
  @Input() padding: number = 20;

  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    
  }

  onButtonClick() {
    this.onClick.emit();
  }

  getButtonType() {
    if(this.buttonText && this.src){
      return 'mixed'
    }

    if(this.buttonText && !this.src){
      return 'text'
    }

    if(!this.buttonText && this.src){
      return 'image'
    }
  }
}
