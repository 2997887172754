import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollService } from 'src/app/portal/services/scroll.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(
    private _scrollService: ScrollService,
    private _router: Router) { }

  ngOnInit(): void {

  }

  scrollToSection(section: string) {
    this._scrollService.changeSection(section);
  }

  redirectToLogin() {
    this._router.navigate(['backoffice/login'])
  }
}
