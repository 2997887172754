import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-section',
  templateUrl: './footer-section.component.html',
  styleUrls: ['./footer-section.component.scss']
})
export class FooterSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  redirectTo(url: string):void {
    window.open(url, "_blank");
  }
}
