import { Component, Input, OnInit } from '@angular/core';
import { OpinionDto } from 'src/app/services/service-proxy';

@Component({
  selector: 'app-opinion',
  templateUrl: './opinion.component.html',
  styleUrls: ['./opinion.component.scss']
})
export class OpinionComponent implements OnInit {

  @Input() opinion: OpinionDto;

  constructor() { }

  ngOnInit(): void {
  }

}
