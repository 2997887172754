<form class="contact-form" #form [formGroup]="formGroup" (submit)="sendMessage()">
    <div>
        <span>Napisz do mnie!</span>
    </div>

    <div class="form-control">
        <input formControlName="fullName" type="text" name="fullName" placeholder="Imię i nazwisko">
        <div *ngIf="controls.fullName.errors && controls.fullName.touched">
            <div *ngIf="controls.fullName.errors.required" class="form-error">Imię i nazwisko jest wymagane</div>
        </div>
    </div>

    <div class="form-control">
        <input formControlName="emailAddress" type="email" name="emailAddress" placeholder="E-mail">
        <div *ngIf="controls.emailAddress.errors && controls.emailAddress.touched">
            <div *ngIf="controls.emailAddress.errors.required" class="form-error">Adres email jest wymagany</div>
            <div *ngIf="controls.emailAddress.errors.email" class="form-error">Niepoprawny adres email
            </div>
        </div>
    </div>

    <div class="form-control area">
        <textarea formControlName="message" type="text" name="message" placeholder="Wiadomość"></textarea>
        <div *ngIf="controls.message.errors && controls.message.touched">
            <div *ngIf="controls.message.errors.required" class="form-error">Treść wiadomości jest wymagana</div>
        </div>
    </div>

    <div class="form-control center">
        <button type="submit" [disabled]="!formGroup.valid || !formGroup.dirty">Wyślij</button>
    </div>
</form>