import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PortalClient, SendContactFormCommand } from 'src/app/services/service-proxy';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {

  formGroup: UntypedFormGroup = this._formBuilder.group({
    fullName: ['', [Validators.required]],
    emailAddress: ['', [Validators.required, Validators.email]],
    message: ['', [Validators.required]],
  });

  get controls() {
    return this.formGroup.controls;
  }

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _contactClient: PortalClient,
    private _toastService: ToastService
  ) { }

  ngOnInit(): void {
  }

  public sendMessage() {
    if (this.formGroup.valid) {
      let command = this.getContactMessageCommand();
      this._contactClient.contact(command).subscribe( 
        {
        next: () => {
          this._toastService.showSuccessToast('Wiadomość została wysłana. Wkrótce się do Ciebie odezwę.');
          this.formGroup.reset();
        },
        error: () => this._toastService.showErrorToast('Wystąpił błąd podczas wysyłania wiadomości. Spróbuj ponownie później.'),
        }
      );
    }
  }

  private getContactMessageCommand(): SendContactFormCommand {
    const formValues = this.formGroup.getRawValue();

    return new SendContactFormCommand({
      fullname: formValues.fullName,
      email: formValues.emailAddress,
      content: formValues.message
    });
  }

}
