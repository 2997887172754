import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalComponent } from './portal.component';
import { AboutSectionComponent } from './sections/about-section/about-section.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderSectionComponent } from './sections/header-section/header-section.component';
import { OfferSectionComponent } from './sections/offer-section/offer-section.component';
import { OpinionsSectionComponent } from './sections/opinions-section/opinions-section.component';
// import { ContactSectionComponent } from './sections/contact-section/contact-section.component';
import { FooterSectionComponent } from './sections/footer-section/footer-section.component';
import { NavbarComponent } from './sections/header-section/navbar/navbar.component';
import { ScrollService } from './services/scroll.service';
import { CarouselModule } from 'primeng/carousel';
import { OpinionComponent } from './sections/opinions-section/opinion/opinion.component';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
// import { OfferComponent } from './sections/offer-section/offer/offer.component';
import { SharedModule } from '../shared/shared.module';
// import { ContactClient, OffersClient, OpinionClient } from '../services/service-proxy';

@NgModule({
  declarations: [
    PortalComponent,
    HeaderSectionComponent,
    AboutSectionComponent,
    OfferSectionComponent,
    OpinionsSectionComponent,
    // ContactSectionComponent,
    FooterSectionComponent,
    NavbarComponent,
    OpinionComponent,
    // OfferComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    ButtonModule,
    ToastModule,
    SharedModule
  ],
  providers: [
    ScrollService,
    // OffersClient,
    // ContactClient,
    // OpinionClient
  ]
})
export class PortalModule { }
