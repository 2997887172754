import { Component, Input, OnInit } from '@angular/core';
import { OfferDto, OffersClient, PagedQueryInput } from 'src/app/services/service-proxy';

@Component({
  selector: 'app-offer-section',
  templateUrl: './offer-section.component.html',
  styleUrls: ['./offer-section.component.scss']
})
export class OfferSectionComponent implements OnInit {

  offers: OfferDto[];

  constructor(
    private _offersClient: OffersClient
  ) { }

  ngOnInit(): void {
    this._offersClient.getOffers(new PagedQueryInput({
      skip: 0,
      pageSize: 100
    })).subscribe(x => {
      this.offers = x.offers;
    });
  }
}
