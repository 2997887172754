import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PortalV1Component } from './portal-v1/portal-v1.component';

const routes: Routes = [
  { path: 'home', component: PortalV1Component  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: '' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
